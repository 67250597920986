import PropTypes from 'prop-types';
/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';
import Section from '../../molecules/Section/Section';
import TextBlock from '../../molecules/TextBlock/TextBlock';
import IconGridSection from '../IconGridSection/IconGridSection';
import {
  fontWeights,
  lineHeights,
  unitConverter as uc,
} from '../../../styles/base';

const TextBlockWithIconRowSection = ({
  iconGridSection,
  sectionStyles,
  textBlock,
}) => {
  const sectionCss = css`
    .icon-grid-section {
      > div > div {
        padding: 0;
      }

      .captioned-icon {
        img {
          width: ${uc('125px')};
          max-height: 100%;
        }

        p {
          font-weight: ${fontWeights.normal};
          line-height: ${lineHeights.copy};
          text-transform: none;
        }
      }
    }
  `;

  return (
    <Section css={sectionCss} sectionStyles={sectionStyles}>
      <TextBlock {...textBlock} />
      <IconGridSection {...iconGridSection} />
    </Section>
  );
};

TextBlockWithIconRowSection.propTypes = {
  iconGridSection: PropTypes.shape({}),
  sectionStyles: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({}),
  ]),
  textBlock: PropTypes.shape({}),
};

TextBlockWithIconRowSection.defaultProps = {
  iconGridSection: false,
  sectionStyles: {},
  textBlock: false,
};

export default TextBlockWithIconRowSection;
