import PropTypes from 'prop-types';
/** @jsx jsx needed to get Storybook to render */
import { jsx, css } from '@emotion/core';
import Image from '../../atoms/Image/Image';
import Button from '../../atoms/Buttons/Button/Button';
import { colors, unitConverter as uc } from '../../../styles/base';

const containerCss = css`
  margin: auto;
`;

const assetDownloadCss = css`
  max-width: ${uc('350px')};
  margin-right: auto;
  margin-left: auto;
  padding: ${uc('30px 50px')};
  background-color: ${colors.white};
  border: solid ${uc('2px')} ${colors.lightGray.one};
  border-radius: ${uc('25px')};
`;

const imageCss = css`
  margin-bottom: ${uc('20px')};
`;

const buttonWrapperCss = css`
  display: flex;
  justify-content: center;
`;

const AssetDownload = ({ assetURL, label, previewURL }) => (
  <div css={containerCss}>
    <div css={assetDownloadCss}>
      <Image css={imageCss} src={previewURL} />
      <div css={buttonWrapperCss}>
        <Button color="primary" link to={assetURL}>
          {label}
        </Button>
      </div>
    </div>
  </div>
);

AssetDownload.propTypes = {
  assetURL: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  previewURL: PropTypes.string.isRequired,
};

export default AssetDownload;
